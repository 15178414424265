import { DOCUMENT } from '@angular/common';
import {
  Directive,
  AfterViewInit,
  OnDestroy,
  Output,
  HostBinding,
  Inject,
  HostListener,
  EventEmitter,
} from '@angular/core';

type EventWithFromElement = Event & { fromElement?: HTMLElement };

@Directive({
  selector: '[dragDrop]',
  standalone: false
})
export class DragDirective implements AfterViewInit, OnDestroy {
  @Output() public filesDropped: EventEmitter<FileList> = new EventEmitter();

  @HostBinding('class') public class = 'drag-drop-video hide';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public ngAfterViewInit(): void {
    this.onDragEnter = (): void => {
      this.showMe();
    };

    this.onDragLeave = (event: EventWithFromElement): void => {
      const isLeavingBrowser = event?.fromElement === null;
      isLeavingBrowser ? this.hideMe() : undefined;
    };

    this.onMouseUp = (): void => {
      this.hideMe();
    };

    this.addListeners(this.onDragEnter, this.onDragLeave, this.onMouseUp);
  }

  private addListeners(
    onDragEnter: (event: EventWithFromElement) => void,
    onDragLeave: (event: EventWithFromElement) => void,
    onMouseUp: (event: EventWithFromElement) => void
  ): void {
    this.document.addEventListener('dragenter', onDragEnter);
    this.document.addEventListener('dragleave', onDragLeave);
    this.document.addEventListener('mouseup', onMouseUp);
  }

  private removeListeners(
    onDragEnter: (event: EventWithFromElement) => void,
    onDragLeave: (event: EventWithFromElement) => void,
    onMouseUp: (event: EventWithFromElement) => void
  ): void {
    this.document.removeEventListener('dragenter', onDragEnter);
    this.document.removeEventListener('dragleave', onDragLeave);
    this.document.removeEventListener('mouseup', onMouseUp);
  }

  private onDragEnter(): void {
    console.warn('expected empty method "onDragEnter".');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onDragLeave(event: EventWithFromElement): void {
    console.warn('expected empty method "onDragLeave".');
  }
  private onMouseUp(): void {
    console.warn('expected empty method "onMouseUp".');
  }
  private showMe(): void {
    this.class = 'drag-drop-video';
  }
  private hideMe(): void {
    this.class = 'drag-drop-video hide';
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.hideMe();

    const hasFiles = !!(event.dataTransfer && event.dataTransfer.files.length);
    if (!hasFiles) {
      return;
    }

    this.filesDropped.emit(event.dataTransfer.files);
  }

  @HostListener('window:dragover', ['$event'])
  public onDragOver(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.showMe();
  }

  public ngOnDestroy(): void {
    this.removeListeners(this.onDragEnter, this.onDragLeave, this.onMouseUp);
  }
}
