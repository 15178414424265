import { Component } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';

@Component({
  selector: 'main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: false,
})
export class MainLayoutComponent {
  public navigateToMain(): void {
    window.open(`${ConfigService?.config?.B2_URL}`, '_blank');
  }
}
